/*
 * javascript for home page, reports and blog post areas
 */
var $ = require('jquery'),
    animation = require('./components/animation'),
    becomeClientModal = require('./components/become-a-client-modal'),
    carousel = require('./components/carousel'),
    team = require('./components/team'),
    scroller = require('./components/scroller'),
    setupConfirm = require('../../admin/js/setup/confirm'),
    setupConsulting = require('./components/consulting'),
    setupCookies = require('./components/cookies'),
    setupDatasetsShowMore = require('./components/datasets-show-more'),
    setupHamburger = require('./components/hamburger'),
    setupFeather = require('./components/feather'),
    setupModal = require('./components/modal'),
    setupScrollPosition = require('./components/scroll-position'),
    setupStarRating = require('./components/star-rating'),
    setupFuseMarketingPage = require('./components/fuse-marketing-page')
;

require('bootstrap-sass');

let cookies = setupCookies();
becomeClientModal();
carousel();
scroller();
team();
setupConfirm();
setupFeather();
setupHamburger();
setupModal(cookies);
setupScrollPosition(cookies);
setupStarRating();
setupConsulting();
// setupFuseMarketingPage();
