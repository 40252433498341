module.exports = function () {
    document.addEventListener('DOMContentLoaded', function() {

        // multiple star rating components per page
        var starRatingComponents = document.querySelectorAll('.star-rating-component');
        starRatingComponents.forEach(function(starRatingComponent) {
            // get the elements for this star rating component
            var stars = starRatingComponent.querySelectorAll('.star-rating-component a.star');
            var starsSection = starRatingComponent.getElementsByClassName('stars-section')[0];
            var summarySection = starRatingComponent.getElementsByClassName('summary-section')[0];

            stars.forEach(function(star) {
                // on hover, change to yellow
                star.addEventListener('mouseover', function() {
                    var index = parseInt(star.getAttribute('data-star-index'));
                    stars.forEach(function(s, i) {
                        if (i < index) {
                            s.querySelector('i').classList.add('fa-star--active');
                        } else {
                            s.querySelector('i').classList.remove('fa-star--active');
                        }
                    });
                });

                // on mouseout, reset to grey
                star.addEventListener('mouseout', function() {
                    stars.forEach(function(s) {
                        s.querySelector('i').classList.remove('fa-star--active');
                    });
                });

                // on click, submit rating
                star.addEventListener('click', function(element) {
                    element.preventDefault();

                    // remove selected class from all stars
                    stars.forEach(function(star) {
                        star.querySelector('i').classList.remove('fa-star--selected');
                    });

                    // add selected class to this star
                    element.target.classList.add('fa-star--selected');

                    var storeFeedbackUrl = starRatingComponent.getAttribute('data-feedback-store-rating-url');
                    var rating = element.target.parentElement.getAttribute('data-star-index');

                    // send rating to server
                    var xhr = new XMLHttpRequest();
                    xhr.open('POST', storeFeedbackUrl, true);
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    var csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                    xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
                    xhr.send(JSON.stringify({
                        rating: rating
                    }));

                    // ajax request to submit rating
                    var feedbackSubmit = starRatingComponent.getAttribute('data-feedback-submit');
                    if (feedbackSubmit == 'false') {
                        return;
                    }

                    // animate stars section out and summary section in
                    setTimeout(function() {
                        starsSection.style.opacity = 0;
                        summarySection.style.opacity = 1;

                        setTimeout(function() {
                            starsSection.style.display = 'none';
                            summarySection.style.display = 'block';
                        }, 300);
                    }, 2500);
                });
            });
        });
    });

};

