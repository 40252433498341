// scroll position events
var $ = require('jquery');

module.exports = function (cookies) {
    $(function() {
        // show the newsletter popup to people who have not dismissed it already
        // this JS is on all pages, so only do it to
        const modal = $('.newsletter-popup');
        const modalExists = $(modal).length;
        if (!modalExists) {
            // don't setup the scroll watch if there's no newsletter modal on this page
            return;
        }

        // use timeouts to only measure the scroll position once user stops scrolling for a moment
        var timer;
        $(window).on('scroll', function() {
            // if user does not allow use to store cookies, do nothing
            if (window.userHasAcceptedUseOfCookies === false) {
                return;
            }

            // if user has refused the newsletter recently, do nothing
            var mailingListRefused = cookies.getCookie('mailing-list-refused');
            if (mailingListRefused == '1') {
                return;
            }

            // keep resetting the timer on every scroll
            if (timer) {
                clearTimeout(timer);
            }

            // set a timer to measure the scroll position
            timer = setTimeout(function() {
                let pagePosition = $(document).scrollTop();
                let windowHeight = $(window).height();
                let pageHeight = $(document).height() - windowHeight;
                let positionPercentage = Math.floor((pagePosition / pageHeight) * 100);

                // modal status can be hidden, visible or cancelled
                let displayAtHeight = parseInt($(modal).data('modal-display-at-height'));

                if (modalExists) {
                    let modalStatus = $(modal).data('modal-status');
                    if (modalStatus == 'hidden' && positionPercentage > displayAtHeight) {
                        // if user is not logged in (user_id)
                        // and has not signed up already (cookie)
                        // and has not already cancelled the popup (cookie)
                        // then display the newsletter popup
                        $(modal).css('display', 'flex');
                        $(modal).css('position', 'fixed');
                        $('body').css('overflow', 'hidden');
                    }
                }
            }, 2000);
        });
    });
};
