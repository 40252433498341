document.addEventListener('DOMContentLoaded', function() {
    var elementsToAnimate = document.querySelectorAll('.fade-in, .fade-in-from-right, .fade-in-from-left');

    var observer = new IntersectionObserver(function(entries) {
        entries.forEach(function(entry) {
            // if 40% of element is visible, add the fade-in--active class
            if (entry.intersectionRatio >= 0.4) {
                entry.target.classList.add('fade-in--active');
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: [0.0, 0.4, 1.0] });

    // set up an observer
    elementsToAnimate.forEach(function(element) {
        observer.observe(element);
    });
});