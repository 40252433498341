var checkboxes = document.querySelectorAll('.datasets .report-territory');
var showMoreBtn = document.querySelector('.datasets #show-more-btn');
var showLessBtn = document.querySelector('.datasets #show-less-btn');
var hiddenCheckboxes = [];

if (checkboxes) {
    checkboxes.forEach(function (checkbox, index) {
        if (index >= 9) {
            checkbox.style.display = 'none';
            hiddenCheckboxes.push(checkbox);
        }
    });
}

if (showMoreBtn) {
    showMoreBtn.addEventListener('click', function () {
        hiddenCheckboxes.forEach(function (checkbox) {
            checkbox.style.display = 'inline-block';
        });

        showMoreBtn.style.display = 'none';
        showLessBtn.style.display = 'inline-block';
    });
}

if (showLessBtn) {
    showLessBtn.addEventListener('click', function () {
        hiddenCheckboxes.forEach(function (checkbox) {
            checkbox.style.display = 'none';
        });

        showMoreBtn.style.display = 'inline-block';
        showLessBtn.style.display = 'none';
    });
}
