/*
 * Setup reusable confirmation dialogues that can be triggered using data
 * attributes.
 */
var $ = require('jquery');
var bootstrap = require('bootstrap-sass');
var bootbox = require('bootbox');

/**
 * JSON response actions
 *
 * By default, we reload the page
 * Remove: respond with a id to remove e.g. 'remove' => "tag-$id" and make sure the id is in the HTML
 * Alert, Redirect
 *
 */
var action = function (data) {
    let reloadPage = true;

    // remove an element by id
    if (typeof data.remove !== 'undefined') {
        let id = data.remove;
        document.getElementById(id).outerHTML = '';

        reloadPage = false;
    }

    // display an alert
    if (typeof data.alert !== 'undefined') {
        alert(data.alert);

        reloadPage = false;
    }

    // redirect to a specific url
    if (typeof data.redirect !== 'undefined') {
        window.location.replace(data.redirect);

        reloadPage = false;
    }

    // otherwise reload the page
    if (reloadPage) {
        window.location.reload();
    }
};

/*
 * Setup confirmation boxes that trigger an ajax request.
 *
 * Example usage:
 *
 *     <a
 *         href="http://target.url/do/action"
 *         data-confirm="ajax"
 *         data-confirm-message="Are you sure you want to do this?"
 *         data-confirm-method="POST"
 *     >
 *     </a>
 *
 * If your element isn't an anchor, use 'data-confirm-url' instead of 'href'.
 * You can also omit 'data-confirm-method' - a GET request will be used by
 * default. Finally, you can omit 'data-confirm-message' too, a sensible default
 * will be used if so.
 *
 * Your endpoint should return JSON. If your reponse data contains an attribute
 * called 'redirect', your user will be redirected to it. If not, the current
 * page will be reloaded. This is true for success and error callbacks.
 *
 * return response()->json([
 *     'redirect' => route('admin.admin-users.index')
 * ]);
 *
 */
var setupAjaxConfirmation = function () {
    $('[data-confirm="ajax"]').on('click', function (e) {
        var $c = $(e.currentTarget);

        e.preventDefault();

        // Fetch the dynamic message
        const message = $c.attr('data-confirm-message') || 'Are you sure?';

        bootbox.confirm(message, function (confirmed) {
            if (confirmed) {
                // Check if the data-submit-form attribute is present
                if ($c.data('submit-form')) {
                    // Find the closest form and submit it
                    $c.closest('form').submit();
                    return;
                }

                $.ajax({
                    url: typeof $c.attr('href') !== 'undefined'
                        ? $c.attr('href')
                        : $c.data('confirm-url'),
                    method: $c.data('confirm-method') || 'GET',
                    dataType: 'json',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    error: function (jqXHR) {
                        action($.parseJSON(jqXHR.responseText));
                    },
                    success: function (data) {
                        action(data);
                    },
                });
            }
        });
    });
};

module.exports = function () {
    setupAjaxConfirmation();
}
