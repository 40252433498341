module.exports = function () {
    var modal = document.getElementById('modal');
    var modalContent = document.getElementById('modal-content');
    var becomeClientButton = document.getElementsByClassName('become-a-client');
    var closeButton = document.getElementsByClassName('close')[0];
    var body = document.querySelector('body');

    // add listener to all the becomeClientButtons
    for (let i = 0; i < becomeClientButton.length; i++) {
        becomeClientButton[i].addEventListener('click', function(event) {
            modal.style.display = 'block';
            body.style.overflow = 'hidden';
        });
    }

    if (!closeButton) {
        return;
    }

    closeButton.addEventListener('click', function(event) {
        event.preventDefault();

        modal.style.display = 'none';
        body.style.overflow = 'auto';
    });

    // modalContent.addEventListener('click', function(event) {
    //     event.preventDefault();
    //     event.stopPropagation();

    //     modal.style.display = 'none';
    //     body.style.overflow = 'auto';
    // });

    // modal.addEventListener('click', function(event) {
    //     event.preventDefault();
    //     event.stopPropagation();

    //     modal.style.display = 'none';
    //     body.style.overflow = 'auto';
    // });
};

