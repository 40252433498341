
var $ = require('jquery');

module.exports = function (cookies) {
    document.addEventListener('DOMContentLoaded', function() {

        // multiple modals
        var modals = document.querySelectorAll('.modal-overlay');
        modals.forEach(function(modal) {

            // check if the modal should be visible
            var modalVisible = modal.getAttribute('data-modal-status') === 'visible';
            if (modalVisible) {
                modal.style.display = 'flex';
                modal.style.position = 'fixed';
                document.body.style.overflow = 'hidden';
            }

            // close button
            var closeButton = modal.querySelector('.modal-close-button');
            if (closeButton) {
                closeButton.addEventListener('click', function(event) {
                    event.preventDefault();

                    modal.setAttribute('data-modal-status', 'cancelled');

                    // use our cookie library to remember their choice
                    cookies.setCookie('mailing-list-refused', 1, 14);

                    modal.style.display = 'none';
                    document.body.style.overflow = 'visible';
                });
            }

            // close modal if click on overlay
            modal.addEventListener('click', function(event) {
                if (event.target.classList.contains('modal-close-button')) {
                    return true;
                }

                // allow anything other than DIVs to be clicked
                if (event.target.nodeName !== 'DIV') {
                    return;
                }

                event.preventDefault();
                if (event.target !== this) {
                    return;
                }

                // never close - if set to true, ignore clicks on the overlay
                var neverClose = modal.getAttribute('data-modal-never-close');
                if (neverClose) {
                    return;
                }

                modal.setAttribute('data-modal-status', 'cancelled');
                modal.style.display = 'none';
                document.body.style.overflow = 'visible';
            });
        });

        // open modal
        var openModal = document.querySelector('.modal-open-button');
        if (openModal) {
            openModal.addEventListener('click', function(event) {
                event.preventDefault();

                var modalId = this.getAttribute('data-modal-id');
                var modal = document.getElementById(modalId);

                modal.setAttribute('data-modal-status', 'visible');
                modal.style.display = 'flex';
                document.body.style.overflow = 'hidden';
            });
        }

        // close modal
        var closeModal = document.querySelector('.modal-close-button');
        if (closeModal) {
            closeModal.addEventListener('click', function(event) {
                event.preventDefault();

                var modalId = this.getAttribute('data-modal-id');
                var modal = document.getElementById(modalId);

                modal.setAttribute('data-modal-status', 'cancelled');
                modal.style.display = 'none';
                document.body.style.overflow = 'visible';
            });
        }
    });
};
