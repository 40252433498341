// cookies
window.userHasAcceptedUseOfCookies = false;

module.exports = function () {
    // check if the user has accepted the use of cookies on the site
    const checkCookies = () => {
        const cookiesAccept = getCookie('cookies-accept');
        if (cookiesAccept !== '1') {
            const banner = document.getElementById('gdpr-banner');
            banner.setAttribute('style', 'display: block');
        } else {
            // store user preference in a global variable
            window.userHasAcceptedUseOfCookies = true;
        }
    };

    // set a cookie
    const setCookie = (cookieName, cookieValue, cookieExpiryDays) => {
        const date = new Date();
        date.setTime(date.getTime() + (cookieExpiryDays * 24 * 60 * 60 * 1000));

        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/`;
    };

    // get a cookie
    const getCookie = (cookieName) => {
        const name = `${cookieName}=`;
        const cookiesArray = document.cookie.split(';');
        for (let i = 0; i < cookiesArray.length; i++) {
            let cookie = cookiesArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }

            if (cookie.indexOf(name) == 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }

        return '';
    };

    // has the user accepted the use of cookies?
    const acceptCookies = (event) => {
        event.preventDefault();

        // use a cookie to remember their choice
        setCookie('cookies-accept', 1, 365 * 5);

        // hide the gdpr banner
        const banner = document.getElementById('gdpr-banner');
        banner.setAttribute('style', 'display:none;');

        // store user preference in a global variable
        window.userHasAcceptedUseOfCookies = true;
    };

    // add an event to the gdpr form
    const gdprForm = document.getElementsByClassName('js-form-gdpr')[0];
    if (gdprForm) {
        gdprForm.addEventListener('submit', acceptCookies);
    }

    window.onload = checkCookies();

    return {
        setCookie,
        getCookie
    };
};
