// scroll position events
var $ = require('jquery');

module.exports = function () {
    $(function() {

        // fade consultants
        $('.consultant img').css('opacity', 0.5);
        $('.consultant img').css('border', 'none');
        $('.consultant img').css('cursor', 'pointer');

        // show Mark
        selectConsultant('mark');

        // click on a consultant to show bio
        $('.consultant a, .consultant img').on('click', function (event) {
            event.preventDefault();

            const consultant = $(this).closest('.consultant');
            const identifier = consultant.data('consultant');

            selectConsultant(identifier);
        });

        // show bio
        function selectConsultant(identifier) {
            // reset selected, hide bio
            $('.consultant img').css('opacity', 0.5);
            $('.consultant img').css('border', 'none');
            $('.bio').addClass('visually-hidden');

            // selected style and show bio
            $('.consultant[data-consultant=' + identifier + '] img').css('opacity', 1);
            // $('.consultant[data-consultant=' + identifier + '] img').css('border', '5px solid #2f86b1');
            $('.bio[data-consultant=' + identifier + ']').removeClass('visually-hidden');
        }

        // add event to show modal case study
        $('#case-studies-container').on('click', '.product .case-study-more-detail, .product img', function (event) {
            event.preventDefault();

            const product = $(this).closest('.product');
            const caseStudyIdentifier = product.attr('data-case-study');

            openModal(caseStudyIdentifier);
        });

        // add event to the load more button
        const loadMoreCaseStudiesButton = document.querySelector('#load-more-case-studies-button');
        if (loadMoreCaseStudiesButton) {
            loadMoreCaseStudiesButton.addEventListener('click', (event) => {
                event.preventDefault();

                // unhide all the extra case studies
                const caseStudies = document.querySelectorAll('.product.visually-hidden');
                caseStudies.forEach(function(userItem) {
                    userItem.className = 'product';
                });

                // hide the "load more" button
                loadMoreCaseStudiesButton.className = 'visually-hidden';
            });
        }

        // closing of modal is handled in modal.js

        function openModal(caseStudyIdentifier) {
            const modal = $('.modal-overlay');
            const modalExists = $(modal).length;
            if (!modalExists) {
                // there is no modal on this page
            }

            const caseStudyJson = caseStudyData[caseStudyIdentifier];
            if (!caseStudyJson) {
                return;
            }

            // update modal html
            $('.consulting-case-study__name').text(caseStudyJson['title']);
            $('.consulting-case-study__bio').text(caseStudyJson['description']);

            if (caseStudyJson['report']) {
                $('.consulting-case-study__report a').attr('href', caseStudyJson['report']);
                $('.consulting-case-study__report').removeClass('visually-hidden');
            } else {
                $('.consulting-case-study__report').addClass('visually-hidden');
            }

            const imagePath = '/img/consulting/' + caseStudyJson['imageName'] + '.jpg';
            $('.consulting-case-study__image img').attr('src', imagePath);

            // modal status can be hidden, visible or cancelled
            if (modalExists) {
                let modalStatus = $(modal).data('modal-status');
                if (modalStatus == 'hidden' || modalStatus == 'cancelled') {
                    $(modal).css('display', 'flex');
                    $(modal).css('position', 'fixed');
                    $('body').css('overflow', 'hidden');
                }
            }
        }

        const caseStudyData = {
            'vision': {
                title: 'Vision and Mission',
                imageName: 'red',
                // subtitle: 'Vision and Mission',
                description: 'MIDiA was asked by a leading performing rights organisation representing songwriters and publishers, to help create a new five-year Vision and Mission. We spoke 1:1 with each member of the Board, and facilitated a strategy day with Vision & Mission workshops. The results have provided the organisation with a clear vision of the future and a renewed sense of purpose. This includes new public-facing Vision & Mission statements.',
            },
            'pioneering': {
                title: 'Pioneering AVOD in video technology',
                imageName: 'sea',
                // subtitle: 'Pioneering AVOD for a video technology provider',
                description: 'MIDiA was commissioned by a video technology provider to help the company embark on a transformation of its business from the onerous servicing of large cable clients to building a fit-for-purpose over the top video platform, with SAAS at its core. The company needed a strong vision and business case to help drive the transformation. MIDiA conducted a deep dive into the global SVOD and AVOD markets, and produced short, inspiring case studies of how new players have built successful SVOD and AVOD propositions in live streaming, sports, news and niche TV & film. From there, MIDiA conducted a Stakeholder Review and workshops to develop strategic options, finally delivering a thought-provoking Vision Paper to help bring clarity of focus, align the board and set the company a new roadmap.',
            },
            'indie': {
                title: 'Exploring new users for a global creator tools player',
                imageName: 'pink',
                // subtitle: 'Opportunities in the rapidly growing indie music sector',
                description: 'A client that builds a widely used product for creators was looking for their next phase of growth through acquisition of new users. They were finding it difficult to find these creators within their own research and commissioned MIDiA to find and survey them. MIDiA created a survey that split a sample of the company’s users and non-users and presented the analysis to a team of stakeholders. We were able to provide insight into key opportunities, including identifying a specific sizable segment of non-user creators that mapped closely in needs and profile to core active users.',
            },
            'creator': {
                title: 'Artists | Goals, dreams and income streams',
                imageName: 'gra',
                // subtitle: 'Investment in the rapidly developing creator economy',
                description: 'MIDiA worked with a streaming service to help gain a better understanding of the financial issues affecting artists across different income brackets. To do this, MIDiA surveyed over 400 artists in North America, alongside in-depth interviews with artists. The survey enabled us to understand the differences between artists at different career, income and development stages. Alongside the interviews, we identified key themes related to common financial concerns as well as long-term goals. The findings were delivered to the leadership team and stakeholders across the company.',
            },
            'sync': {
                title: 'Investment in the sync & production music space',
                imageName: 'leaf',
                // subtitle: 'Investment in the changing sync & production music space',
                description: 'MIDiA has supported a number of companies in the sync space - from those looking to raise investment for new propositions to funds looking to invest. We have mapped the size and structure of the sync space, including revenues that flow through the various segments (royalty free, production music, commercial catalogues) and key players (production libraries, labels, publishers, bespoke providers, agencies). We have analysed key \'buy-side\' verticals (advertising, TV, film, games) in terms of regional and global outlook. We combined this comprehensive market modelling with expert interviews with leaders across the sync industry to gather key trends and dynamics in the space. Our knowledge of this complex and hard-to-define space has seen us produce groundbreaking work. Our reports have brought quantified insights and clarity for clients on the ‘sell-side’, in sync technology/services and investors.',
            },
            'tunecore': {
                title: 'SoundCloud | Building a fan economy with Fan Powered Royalties',
                imageName: 'art',
                // subtitle: 'State of nation study on women making music (White Paper)',
                description: 'Over the previous year, SoundCloud has reimagined the process of paying creators by embracing a user-centric licensing system, labelled as Fan Powered Royalties (FPR). MIDiA analysed a year\'s worth of streaming data for the sample of artists who had been on the new pay-out model in order to put together a white paper of the results. MIDiA\'s team were able to carve through a complex dataset and present a simple, but effective, narrative of what this change means for artists and rightsholders. Furthermore, MIDiA explored the long-term implications of this change, taking the analysis to a deeper level to uncover the potential of a fan-driven economy that was underpinned by FPR.',
            },
            'anghami': {
                title: 'Anghami: Regional and local streaming markets (Middle East)',
                imageName: 'circ',
                // subtitle: 'Regional and local streaming markets (Middle East) (White Paper)',
                description: 'Anghami commissioned MIDiA to publish new research on the MENA region. The study focused on consumer behaviours, key differences in music consumption/streaming behaviours and music tastes. To draw comparisons on the different services and their business models across emerging markets, we expanded the scope to case studies of highly successful regional streaming services in markets such as India, China, Nigeria and South Africa highlighting the role of local services in developing emerging music markets. The report positions the regional services as offering an alternative to the big three global services (Spotify, Apple Music, Amazon Music) with an analysis of why, covering: content, product, business model and brand. Case Studies are included from India, China, Nigeria and South American music markets.',
                report: '/reports/local-sounds-global-cultures-mena-music-market-opportunity'
            },
            'marketing': {
                title: 'Global music marketing - issues and challenges for top tier marketers',
                imageName: 'yel',
                // subtitle: 'Global music marketing - issues and challenges for top tier marketers',
                description: 'We conducted a major survey of marketing challenges and opportunities covering a ‘before and during’ view of the impact of COVID-19. Our study included 50 1:1 interviews with marketers including indies, distributors, marketing agencies and artist managers, together with a survey of over 400 music marketers around the world. The study enabled our client to have a clear understanding of marketing challenges and to come up with a strategy to develop a suite of promotional and strategic planning tools for artist marketers.',
            },
            'repositioning': {
                title: 'Repositioning and re-inventing music radio',
                imageName: 'red',
                // subtitle: 'Repositioning and re-inventing music radio',
                description: 'MIDiA has worked with several national radio broadcast brands on repositioning music radio in the streaming era. With the competition for ‘earspace’ intensifying and streaming platforms embracing new audio formats such as podcasts and audiobooks, ‘traditional’ broadcast radio knows it must transform quickly, to hold onto audiences and relevance in the media landscape. MIDiA has worked with radio brands on music-related content and product strategies. Our work follows a ‘tried and trusted’ method - introducing radio brand leaders to enlightening market trends and assessing how they can compete with streaming through differentiated strategies that play to core strengths yet meet changing audiences needs. We have conducted competitive analysis, industry partner consultations (music labels, publishers, artists and managers) to help radio brands re-define their market position with more purpose, confidence and future-focus, including new content formats, app strategies and audience analytics.',
            },
            'utopia': {
                title: 'Twitch | Music & gaming: a new way to play',
                imageName: 'mesh',
                // subtitle: 'Repositioning and re-inventing music radio',
                description: 'In partnership with Twitch, the report, Music & Gaming: A new way to play, examined the growing opportunity for music artists to leverage the games industry’s fan economy to develop their careers, connect with their audiences, and monetise their craft. The report was based on a series of in-depth interviews with key players in the world of music and gaming. The report shows how artists and music companies can capture the full potential of this opportunity by reimagining and expanding the way they currently participate in the gaming opportunity, shifting away from marketing and promotion to drive streams on digital music services to fully immersive content creation\'in-game\'.',
                report: '/reports/music-and-gaming-a-new-way-to-play'
            }
        };
    });
};
