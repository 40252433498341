document.addEventListener('DOMContentLoaded', function() {
    // hero
    let currentWordIndex = 0;
    let words = ['decision making', 'marketing plan', 'business strategy'];
    const wordContainer = document.querySelector('#word-container');

    // dont run this code on every page
    if (!wordContainer) {
        return;
    }

    function rotateParagraph() {
        currentWordIndex = (currentWordIndex + 1) % words.length;
        wordContainer.innerHTML = words[currentWordIndex];
    }

    setInterval(rotateParagraph, 3000);

    // segments
    function createSegmentClones(container) {
        const segmentItems = container.querySelector('.segment-items');
        console.log(segmentItems);
        const segmentItemsChildren = Array.from(segmentItems.children);
        const segmentWidth = segmentItemsChildren.reduce((totalWidth, segmentItem) => {
            const style = window.getComputedStyle(segmentItem);
            const itemWidth = segmentItem.offsetWidth + parseInt(style.marginLeft) + parseInt(style.marginRight);
            return totalWidth + itemWidth;
        }, 0);

        const containerWidth = segmentItems.offsetWidth;
        let totalWidth = segmentWidth;

        // Cloning the segments
        while (totalWidth < containerWidth * 2 + segmentWidth) {
            segmentItemsChildren.forEach(item => {
                const clone = item.cloneNode(true);
                segmentItems.appendChild(clone);
                totalWidth += clone.offsetWidth + parseInt(window.getComputedStyle(clone).marginLeft) + parseInt(window.getComputedStyle(clone).marginRight);
            });
        }

        // Animation infinite carousel effect
        const animationDuration = totalWidth / segmentWidth * 80;

        let keyframes = '';
        if (container.dataset.index == 0 || container.dataset.index == segmentContainers.length - 1) {
            keyframes = `@keyframes slideSegments-${container.dataset.index} {
                0% { transform: translateX(-${totalWidth - containerWidth}px); }
                100% { transform: translateX(0); }
            }`;
        } else {
            keyframes = `@keyframes slideSegments-${container.dataset.index} {
                0% { transform: translateX(0); }
                100% { transform: translateX(-${totalWidth - containerWidth}px); }
            }`;
        }

        const styleSheet = document.createElement('style');
        styleSheet.innerHTML = keyframes;
        document.head.appendChild(styleSheet);

        segmentItems.style.animation = `slideSegments-${container.dataset.index} ${animationDuration}s linear infinite`;
    }

    const segmentContainers = document.querySelectorAll('.segments-container');

    // Create segment clones for each container
    segmentContainers.forEach((container, index) => {
        container.dataset.index = index;
        createSegmentClones(container);
    });
});