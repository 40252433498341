module.exports = function () {
    if (window.location.pathname === '/') {
        document.addEventListener('DOMContentLoaded', function(event) {
            var clientsActive = document.getElementById('clientsActive');
            var contactActive = document.getElementById('contactActive');
            var reportsActive = document.getElementById('reportsActive');
            var consultingActive = document.getElementById('consultingActive');

            document.addEventListener('scroll', () => {
                var windowPosition = document.documentElement.scrollTop;
                var windowHeight = window.innerHeight;

                if (windowPosition > 0) {
                    let screenPosition = windowPosition - windowHeight;
                    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        reportsActive.setAttribute('class', (screenPosition > 1730) ? 'active' : null);
                        consultingActive.setAttribute('class', (screenPosition > 3280) ? 'active' : null);
                        clientsActive.setAttribute('class', (screenPosition > 4530) ? 'active' : null);
                        contactActive.setAttribute('class', (screenPosition > 6810) ? 'active' : null);
                    } else {
                        reportsActive.setAttribute('class', (screenPosition > 820) ? 'active' : null);
                        consultingActive.setAttribute('class', (screenPosition > 1995) ? 'active' : null);
                        clientsActive.setAttribute('class', (screenPosition > 2595) ? 'active' : null);
                        contactActive.setAttribute('class', (screenPosition > 3000) ? 'active' : null);
                    };
                }
            });
        });
    }
}