module.exports = function () {
    // home page carousel
    let mainPosts = document.querySelectorAll('.main-post');
    let posts = document.querySelectorAll('.post');

    // add event listener to posts
    posts.forEach((post, index) => {
        post.addEventListener("click", function () {
            clearInterval(progressInterval);
            updateCarousel(index);
            progressInterval = setInterval(progress, 18);
        });
    });

    let i = 0;
    let postIndex = 0;
    let currentPost = posts[postIndex];
    if (!currentPost) {
        return;
    }

    let currentMainPost = mainPosts[postIndex];
    let progressInterval = setInterval(progress, 18);

    function progress() {
        if (i === 1000) {
            clearInterval(progressInterval);

            // change the slide
            i = -5;
            postIndex++;

            // reset postIndex to loop over the slides again
            if (postIndex === posts.length) {
                postIndex = 0;
            }

            updateCarousel(postIndex);
            progressInterval = setInterval(progress, 18);
        } else {
            // add a tick
            i++;

            // update progress bar
            let widthPercentage = i / 10;
            currentPost.querySelector('.progress-bar__fill').style.width = `${widthPercentage}%`;
            document.querySelector(
                '.progress-bar--primary .progress-bar__fill'
            ).style.width = `${widthPercentage}%`;
        }
    }

    function updateCarousel(index) {
        let previousPost = currentPost;
        currentPost = posts[index];
        currentMainPost = mainPosts[index];
        mainPosts.forEach(mainPost => {
            mainPost.classList.remove('main-post--active');
            mainPost.classList.add('main-post--not-active');
        });

        currentMainPost.classList.remove('main-post--not-active');
        currentMainPost.classList.add('main-post--active');

        // hide progress bar of the previous post
        previousPost.querySelector('.progress-bar').classList.add('hidden');

        // toggle visibility of the progress bar for the current post
        let progressBar = currentPost.querySelector('.progress-bar');
        progressBar.classList.remove('hidden');
    }
};
