// hamburger navigation menu for mobile
module.exports = function () {
    const hamburgerButton = document.querySelectorAll('.js-header-nav-control')[0];

    const toggleNavMenu = () => {
        const navElement = document.querySelectorAll('.js-header-nav')[0];
        const navElementClasses = navElement.getAttribute('class');
        const hamburgerButtonClasses = hamburgerButton.getAttribute('class');

        if (navElementClasses.includes('is-active')) {
            navElement.classList.remove('is-active');
        } else {
            navElement.classList.add('is-active');
        }

        if (hamburgerButtonClasses.includes('is-active')) {
            hamburgerButton.classList.remove('is-active');
        } else {
            hamburgerButton.classList.add('is-active');
        }
    };

    hamburgerButton.addEventListener('click', toggleNavMenu);
};
